
import Mobile from '@/layout/mobile/emptyMobile.vue'

const exam = [

    {
        path: '/signup',
        component: Mobile,
        children: [
            {
                path: 'm_selecttest',
                name: 'MobileSelecttest',
                component: () => import('#/viewsMobile/signup/selectTest.vue'),
                meta: { title: '考试报名', whitelist: true }
            },
            // {
            //     path: 'm_promiseBook',
            //     name: 'MobilePromiseBook',
            //     component: () => import('@/viewsMobile/signup/promiseBook.vue'),
            //     meta: { title: '承诺书' }
            // },
            {
                path: 'm_selectscene',
                name: 'MobileSelectScene',
                component: () => import('#/viewsMobile/signup/selectScene.vue'),
                meta: { title: '选择场次' }
            },
            {
                path: 'm_examInfo',
                name: 'MobileExamInfo',
                component: () => import('#/viewsMobile/signup/confirmInfo.vue'),
                meta: { title: '报名信息' }
            },
            {
                path: 'm_myRegistration/:planid',
                name: 'MobileMyRegistration',
                component: () => import('#/viewsMobile/signup/myRegistration.vue'),
                meta: { title: '退回修改' }
            }
        ]
    }

]

export default exam
