<template>
    <section class="view">
        <router-view v-slot="{ Component }">
            <transition name="fade-transform" mode="out-in" appear>
                <keep-alive :include="include">
                    <component :is="Component"/>
                </keep-alive>
            </transition>
        </router-view>
    </section>

</template>

<script setup>
import { inject } from 'vue'
const $ = inject('$jquery')
const include = [] // 禁止 index (多个文件 name 一致)
// 兼容处理：ios 低版本的部分元素上可能无法触发点击事件
$('body>*').bind('click', function() {})

</script>

<style scoped lang="less">
    .view{
        min-height: auto;
        min-width:auto;
        height: calc(100vh);
    }
</style>
