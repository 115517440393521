
import Mobile from '@/layout/mobile/emptyMobile.vue'

const common = [

    {
        path: '/myLogin',
        component: Mobile,
        hidden: true,
        children: [
            {
                path: '',
                meta: { title: '登录', whitelist: true },
                component: () => import('#/viewsMobile/loginOrRegister/login.vue')
            }
        ]
    },
    {
        path: '/myRegister',
        component: Mobile,
        hidden: true,
        children: [
            {
                path: '',
                meta: { title: '注册', whitelist: true },
                component: () => import('#/viewsMobile/loginOrRegister/register.vue')
            }
        ]
    },

    {
        path: '/news',
        component: Mobile,
        name: 'MobileNews',
        meta: { title: '新闻' },
        children: [
            {
                path: 'm_list',
                name: 'MobileNewsList',
                component: () => import('@/viewsMobile/news/list.vue'),
                meta: { title: '新闻列表', whitelist: true }
            },
            {
                path: 'm_detail/:newsid',
                name: 'MobileNewsDetail',
                component: () => import('@/viewsMobile/news/detail.vue'),
                meta: { title: '新闻详情', whitelist: true }
            },
            {
                path: 'm_qslist',
                component: () => import("@/viewsMobile/news/qsList.vue"),
                name: 'MobileqsList',
                meta: { title: '常见问题' , whitelist: true }
            },
            {
                path: 'm_downloadList',
                component: () => import("@/viewsMobile/news/downloadList.vue"),
                name: 'MobiledownloadList',
                meta: { title: '资料下载' , whitelist: true }
            },
        ]
    },

    {
        path: '/m_forgotPassword',
        component: Mobile,
        hidden: true,
        children: [
            {
                path: '',
                meta: { title: '忘记密码', whitelist: true },
                component: () => import('@/viewsMobile/loginOrRegister/forgotPassword.vue')
            }
        ]
    },

    {
        path: '/redirect',
        children: [
            {
                path: '/redirect/:path(.*)',
                component: () => import('@/views/redirect/index.vue'),
                meta: { whitelist: true }
            }
        ]
    }
]

export default common
