import { createRouter, createWebHashHistory } from 'vue-router'
import Mobile from '@/layout/mobile/emptyMobile.vue'

import common from './modules/common'
import personCenter from '#/router/phone/personCenter'
import exam from '#/router/phone/exam'

/* Router Modules */
/** 常驻路由 */
export const routes = [
    {
        path: '/', // 首页根据配置进行切换，在layout文件夹
        redirect: '/index'
    },
    {
        path: '/index',
        name: 'MobilePostIndex',
        meta: { whitelist: true },
        component: () => import('#/viewsMobile/mobileIndex.vue')
    },
    ...common,
    ...personCenter,
    ...exam
]
//

/**
 * 动态路由
 * 用来放置有权限 (Roles 属性) 的路由
 * 必须带有 Name 属性
 */
export const asyncRoutes = []

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    // 每次切换路由页面滚动到顶部，浏览器后退/前进除外
    scrollBehavior(to, from, savedPosition) {
        // 待定，若有多个页面无需滚回顶部则根据 to 、 from 各自的 meta 参数综合判断
        const flag = to.name === from.name
        if (!flag) {
            if (savedPosition) {
                return savedPosition
            } else {
                return { top: 0 }
            }
        }
    }
})

export default router
